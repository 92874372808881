import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import s from './Violations.scss';
import {ViolationNotification} from './ViolationNotification/ViolationNotification';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../domain/specs';

export enum ViolationsDataHook {
  Root = 'ViolationsDataHook.Root',
}

export const Violations = () => {
  const {
    cartStore: {topThreeViolations},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    topThreeViolations.length > 0 && (
      <div data-hook={ViolationsDataHook.Root}>
        {topThreeViolations.map((violation, index) => {
          return (
            <div
              key={index}
              className={
                /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.notification : s.notificationViewport
              }>
              <ViolationNotification violation={violation} />
            </div>
          );
        })}
      </div>
    )
  );
};
