import React from 'react';
import {getHref} from '../../../../../../../../domain/utils/itemUtils';
import {ICartItem} from '../../../../../../../../types/app.types';
import {useControllerProps, UseControllerPropsResult} from '../../../../../ControllerContext';

export enum ItemLinkDataHooks {
  Anchor = 'ItemLinkDataHooks.Anchor',
  NonClickableItem = 'ItemLinkDataHooks.NonClickableItem',
}

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement>,
  navigationStore: UseControllerPropsResult['navigationStore'],
  urlPart: string
) => {
  event.preventDefault();
  event.stopPropagation();
  return navigationStore.navigateToProduct(urlPart);
};

export const ItemLink = ({item, children}: {item: ICartItem; children: React.ReactChild}) => {
  const {cartStore, navigationStore} = useControllerProps();
  const href = getHref(cartStore, item);

  return item.product.urlPart === '' ? (
    <div data-hook={ItemLinkDataHooks.NonClickableItem}>{children}</div>
  ) : (
    <a
      href={href}
      onClick={(event) => handleClick(event, navigationStore, item.product.urlPart)}
      data-hook={ItemLinkDataHooks.Anchor}>
      {children}
    </a>
  );
};
