import React from 'react';
import {ICartItem} from '../../../../../../types/app.types';
import {ProductThumbnail} from '@wix/wixstores-client-common-components';
import s from './CartItemThumbnail.scss';
import {ItemLink} from '../CartItem/partials/ItemLink/ItemLink';
import {getMediaDimensions} from '../../../../../../domain/utils/itemUtilsWithSideEffect';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../../domain/specs';

export enum CartItemThumbnailDataHook {
  Image = 'CartItemThumbnailDataHook.image',
}

export const CartItemThumbnail = ({item}: {item: ICartItem}) => {
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);
  return (
    <div className={/* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.thumbnail : s.thumbnailViewport}>
      <ItemLink item={item}>
        <ProductThumbnail
          product={item.product}
          {...getMediaDimensions(item, CartItemThumbnail.defaultDimensions)}
          mountedToDOM={true}
        />
      </ItemLink>
    </div>
  );
};

CartItemThumbnail.defaultDimensions = {width: 100, height: 100};
