import React from 'react';
import {RemoveIcon} from '../../../../Icons/Remove';
import {CartItemDataHook} from '../../CartItem';
import {useControllerProps} from '../../../../../ControllerContext';
import s from '../../CartItem.scss';
import {ICartItem} from '../../../../../../../../types/app.types';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../../../../domain/specs';

export const Remove = ({item}: {item: ICartItem}) => {
  const {t} = useTranslation();
  const {removeItemFromCart} = useControllerProps().cartStore;
  const {experiments} = useExperiments();
  const shouldFixPriceOverlapsInCartPage = experiments.enabled(SPECS.ShouldFixPriceOverlapsInCartPage);

  return (
    <button
      className={/* istanbul ignore next */ shouldFixPriceOverlapsInCartPage ? s.remove : s.removeOld}
      type="button"
      aria-label={t('cart.sr_remove_item_from_cart', {item_name: item.product.name})}
      onClick={() => removeItemFromCart(item)}
      data-hook={CartItemDataHook.Remove}>
      <RemoveIcon />
    </button>
  );
};
