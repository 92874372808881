import React from 'react';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../ControllerContext';
import {OrderSummaryDataHook} from '../OrderSummary';
import commonStyles from '../OrderSummary.scss';
import s from './CouponTotal.scss';
import classnames from 'classnames';
import {SPECS} from '../../../../../../domain/specs';

export const CouponTotal = () => {
  const {t} = useTranslation();
  const {appliedCoupon, convertedTotals} = useControllerProps().cartStore.cart;
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    <div
      data-hook={OrderSummaryDataHook.CouponRoot}
      className={classnames(
        /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? commonStyles.divider : commonStyles.dividerViewport,
        s.root
      )}>
      <dl>
        <dt>
          <span data-hook={OrderSummaryDataHook.CouponLabel}>{t('cart.coupon')}</span>{' '}
          <span data-hook={OrderSummaryDataHook.CouponName}>{appliedCoupon.name}</span>
        </dt>
        <dd
          className={s.value}
          data-hook={OrderSummaryDataHook.CouponDiscountValue}
          data-wix-coupon-discount-value={OrderSummaryDataHook.CouponDiscountValue}>
          -{convertedTotals.formattedDiscount}
        </dd>
      </dl>
    </div>
  );
};
