import React from 'react';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {ContinueShopping, ContinueShoppingPosition} from '../ContinueShopping/ContinueShopping';
import {Headline} from '../Headline/Headline';
import {HTMLTag} from '../HTMLTag/HTMLTag';
import s from './EmptyState.scss';
import {SPECS} from '../../../../../domain/specs';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum EmptyStateDataHook {
  root = 'EmptyState.root',
  title = 'EmptyState.title',
}

export const EmptyState = () => {
  const {get} = useSettings();
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    <>
      <Headline />
      <div
        className={/* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.emptyState : s.empty_state_viewPort}
        data-hook={EmptyStateDataHook.root}>
        <HTMLTag data-hook={EmptyStateDataHook.title} type="h3" className={s.title}>
          {get(settingsParams.CART_EMPTY_CART) as string}
        </HTMLTag>
        <ContinueShopping position={ContinueShoppingPosition.emptyState} />
      </div>
    </>
  );
};
